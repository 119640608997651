<template>
  <section class="mb-15">
    <v-container>
      <Title :title="printInfo.title ? printInfo.title : 'Nossas Gravações'" />
      <div
        v-html="printInfo.description"
        :style="$vuetify.breakpoint.mobile ? '' : 'max-width: 40%'"
        class="mb-10 text-xl-h6"
      ></div>
      <v-row>
        <v-col
          v-for="(card, idx) in cards"
          :key="idx"
          cols="6"
          md="3"
        >
          <PrintCard :card="card" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  components: {
    Title: () => import('@/components/web/layout/widgets/Title'),
    PrintCard: () => import('@/components/web/print/PrintCard'),
  },
  data: () => ({
    routes: [{ title: 'Gravações', route: '/print' }],
    cards: [
      {
        src: require('@/assets/prints/img01.png'),
        title: 'Cromia',
        id: 1,
      },
      {
        src: require('@/assets/prints/img02.png'),
        title: 'Digital UV',
        id: 2,
      },
      {
        src: require('@/assets/prints/img03.png'),
        title: 'Etiqueta Resinada',
        id: 3,
      },
      {
        src: require('@/assets/prints/img04.png'),
        title: 'Hot Stamping',
        id: 4,
      },
      {
        src: require('@/assets/prints/img05.png'),
        title: 'Laser',
        id: 5,
      },
      {
        src: require('@/assets/prints/img06.png'),
        title: 'Off-set',
        id: 6,
      },
      {
        src: require('@/assets/prints/img07.png'),
        title: 'Relevo',
        id: 7,
      },
      {
        src: require('@/assets/prints/img08.png'),
        title: 'Silk Screen',
        id: 8,
      },
      {
        src: require('@/assets/prints/img09.png'),
        title: 'Sleeve',
        id: 9,
      },
      {
        src: require('@/assets/prints/img10.png'),
        title: 'Sublimação',
        id: 10,
      },
      {
        src: require('@/assets/prints/img11.png'),
        title: 'Tampografia',
        id: 11,
      },
      {
        src: require('@/assets/prints/img12.png'),
        title: 'Transfer',
        id: 12,
      },
    ],
    printInfo: null,
  }),
  methods: {
    getInfo() {
      this.$api
        .get('prints/info')
        .then((res) => {
          this.printInfo = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getPosts() {
      this.$api
        .get('prints/post')
        .then((res) => {
          this.cards = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  mounted() {
    this.$store.commit('setBreadcrumbs', this.routes)
    this.getInfo()
    this.getPosts()
  },
}
</script>

<style>
.u-cursor-pointer {
  cursor: pointer;
}
</style>
